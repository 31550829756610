import React from "react"
import Layout from "../components/layout"
import HowBrigoWorks from "../components/product/how_brigo_works"

const HowItWorksPage = () => {
  return (
    <Layout>
      <div className="howBrigoWorksPage">
          <HowBrigoWorks />
      </div>  
    </Layout>
  )
}

export default HowItWorksPage
